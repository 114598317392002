import {
  BoldDetail,
  ContentHolder,
  DetailsDiv,
  OuterCard,
  CardText,
  ExpandedCard,
  ExpandedCardDetails,
  ExpandedCardMessage,
  ExpandedCardText,
  ProfileLink,
  CardTitle,
} from "../../styles/ActiveBuyerCardStyles.js";
import * as S from "../ActiveWantsTable/ActiveWantsTable.styled.js";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLeftIcon,
  TagLabel,
  Text,
  Textarea,
  useToast,
  SimpleGrid,
  useDisclosure,
  ModalFooter,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { InnerCardHolder } from "../../styles/ActiveBuyerCardStyles";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime' 
dayjs.extend(relativeTime) // Required for fromNow() method
import { provinceShorter } from "./ListingCard2point0";
import { useAuth } from "../../utils/auth/newAuth";
import { useMutation } from "react-query";
import { getToken } from "../../src/config/firebase";
import slugify from "slugify";
import * as Sentry from "@sentry/nextjs";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { BsFillChatDotsFill, BsLink45Deg } from "react-icons/bs";
import { GoBell } from "react-icons/go";
import { EditIcon, DeleteIcon, CheckIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useRouter } from "next/router";
import { clientAnalytics, clientError } from "../../utils/utilties";

export const formatMoney = (n) => {
  if (n < 1e3) return "$" + n;
  if (n >= 1e3 && n < 1e6)
    return `$${n % 1e3 == 0 ? (n / 1e3).toFixed(0) : (n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9)
    return `$${n % 1e6 == 0 ? (n / 1e6).toFixed(0) : (n / 1e6).toFixed(1)}M`;
  if (n >= 1e9 && n < 1e12)
    return `$${n % 1e9 == 0 ? (n / 1e9).toFixed(0) : (n / 1e9).toFixed(1)}B`;
  if (n >= 1e12)
    return `$${n % 1e12 == 0 ? (n / 1e12).toFixed(0) : (n / 1e12).toFixed(1)}T`;
};

String.prototype["toProperCase"] = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
const ActiveBuyerCard = (props) => {
  const { user, userId, profile } = useAuth();

  const [details, setDetails] = useState(false);
  const {
    triggerAuth,
    setEditMode,
    setWantBoxOpen,
    setEditId,
    id,
    areaType,
    radius,
    cities,
    provinces,
    regions,
    refetch,
    address,
    subject,
    minSize,
    maxSize,
    minPrice,
    businessTypes,
    maxPrice,
    priceInsensitive,
    sizeInsensitive,
    priceType,
    type,
    sizeUnit,
    subTypes,
    propertyTypes,
    timeStamp,
    description,
    User: {
      uid,
      email,
      industryRole,
      firstName,
      fullName,
      photoURL,
      phone,
      slug,
    },
    hideModifyButtons,
    isCollapsed,
    ...rest
  } = props;
  const [messageValue, setMessageValue] = useState("");
  const [messageStatus, setMessageStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [messaged, setMessaged] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("idle");
  const [expandedRows, setExpandedRows] = useState([]);
  const [readMore, setReadMore] = useState(new Map());
  const [messageText, setMessageText] = useState("");
  const [isAuthBoxOpen, setIsAuthBoxOpen] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [messageSendPending, setMessageSendPending] = useState(false);
  const { 
    isOpen: isModalOpen, 
    onOpen: onModalOpen, 
    onClose: onModalClose 
  } = useDisclosure();

  const router = useRouter()

  const onAuthBoxClose = () => {
    setIsAuthBoxOpen(false);
  };

  const proAlert = (button) => {
    setModalMode("upgrade");
    onModalOpen();
    clientAnalytics.track({
      userId: userId,
      event: "Interest in upgrade hook",
      properties: {
        reason: "Send message to want owner",
        button: button,
      },
    });
  };

  useEffect(()=> {
    if (profile && messageSendPending) {
      createMessageMutation.mutate({
        uid: userId,
        message: messageText,
        wantId: id,
      })
    }
  }, [profile])

  const cityListMaker = (cities: String[]) => {
    let cityText = [];
    if (cities.length < 4) {
      cities.map((city) => {
        cityText.push(city["city"] + " " + provinceShorter(city["province"]));
      });
    } else {
      let part1 = cities.slice(0, 3);
      part1.map((city) => {
        cityText.push(city["city"] + " " + provinceShorter(city["province"]));
      });

      let part2 = cities.slice(3);
      let l = part2.length;
      cityText.push(`and ${l} other${l > 1 ? "s" : ""}`);
    }

    return cityText.join(", ");
  };

  const listMaker = (list: String[]) => {
    let theList = [];
    if (list.length < 4) {
      list.map((item) => {
        theList.push(item);
      });
    } else {
      let part1 = list.slice(0, 3);
      part1.map((item) => {
        theList.push(item);
      });

      let part2 = list.slice(3);
      let l = part2.length;
      theList.push(`and ${l} other${l > 1 ? "s" : ""}`);
    }

    return theList.join(", ");
  };

  // Update mutation
  async function updateWantRequest(listingData) {
    setSubmitStatus("loading");

    const response = await fetch(`/api/wants/${id}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: await getToken(),
      },
      body: JSON.stringify({ updatedData: listingData }),
    });

    const data = await response?.json();
    const { want } = data;

    return want;
  }

  const updateMutation = useMutation(updateWantRequest, {
    onError: (error) => {
      clientError("Error updating want: " + error);
      setSubmitStatus("error");
      toast({
        title: "Error",
        description: "Something went wrong. Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },

    onSuccess: (data, variables, context) => {
      if (data) {
        setSubmitStatus("success");
        refetch();
        if (data["status"] == "deleted") {
          toast({
            title: "Want Deleted",
            description: "Your want was deleted successfully.",
            variant: "subtle",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Want changes approved!",
            description: "Your want has been updated.",
            status: "success",
            variant: "subtle",
            duration: 5000,
            isClosable: true,
          });
        }
      } else throw "error";
    },
  });

  const toast = useToast();

  const PosterLine = (
    <div>
      <DetailsDiv>Poster</DetailsDiv>
      <Flex alignItems="center">
        {photoURL && <Avatar size="sm" src={photoURL} mr="5px" />}

        <BoldDetail>{fullName}</BoldDetail>
      </Flex>
    </div>
  );

  async function createUpgradeRequest() {
    setSubmitStatus("loading");
    const response = await fetch("/api/upgrade", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: await getToken(),
      },
      body: JSON.stringify({ upgradeReason: "Message want poster" }),
    });

    const data = await response.json();

    const { theUser } = data;
    return theUser;
  }

  const createUpgradeMutation = useMutation(createUpgradeRequest, {
    onError: (error) => {
      // An error happened!
      toast({
        title: "Error",
        description: `An error occured. Please email as us at hello@zonado.com instead`,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    },

    onSuccess: (data, variables, context) => {
        setSubmitStatus("success");
        router.push("/pro");
        onModalClose();
    },
  });

  async function createMessageRequest(messageData) {
    setMessageStatus("loading");

    const response = await fetch("/api/messages/create?scenario=W1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: await getToken(),
      },
      body: JSON.stringify({ message: messageData }),
    });

    const data = await response.json();
    const {
      theMessage: { convoId },
    } = data;
    return convoId;
  }

  const createMessageMutation = useMutation(createMessageRequest, {
    onError: (error) => {
      // An error happened!
      clientError(error);
      toast({
        title: "Error",
        description: "Something went wrong. Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setMessageStatus("error");
      setErrorMessage("An error has occured, please try again later");
    },

    onSuccess: (data, variables, context) => {
      if (data) {
        setMessaged(true);
        // setMessageBoxOpen(false);
        setMessageStatus("success");
        toast({
          title: `Message sent to ${firstName}!`,
          status: "success",
          duration: 5000,
          variant: "subtle",
          isClosable: true,
        });
      } else {
        throw "An error occured trying to send message to want owner";
      }
    },

    onSettled: () => {
      setMessageSendPending(false);
    },
  });

  const updateReadMore = (key, value) => {
    setReadMore((map) => new Map(map.set(key, value)));
  };

  const copyContent = (listingUrl, id) => {
    navigator.clipboard.writeText(listingUrl);
    clientAnalytics.track("Want Shared", {
      Medium: "Copy and Paste",
      "Want Id": id,
      Prompt: "Wants Table",
    });
  };

  useEffect(() => {
    description.length > 150 && updateReadMore(id, true);
  }, [props]);

  return (
    <>
      <OuterCard
        details={details}
        onClick={() => {
          if (!isCollapsed) {
            setMessageText(
              `Hi ${firstName.toProperCase()}, are you still looking for something like this?`
            );
            !expandedRows.includes(id)
              ? setExpandedRows([...expandedRows, id])
              : null;
          }
        }}
        disable={!expandedRows.includes(id) ? false : true}
      >
        <Flex flexDir={"column"} justifyContent="space-between" height={"100%"}>
          {expandedRows.includes(id) ? (
            <ExpandedCard>
              <ExpandedCardDetails>
                <Flex mb="16px" w={"100%"} justifyContent="space-between">
                  <ProfileLink
                    margin="0"
                    href={`/profile/${slug}`}
                    target="_blank"
                  >
                    <Flex>
                      {photoURL && (
                        <Avatar size="md" src={photoURL} mr="10px" />
                      )}
                      <Box>
                        <S.ExpandedDetailsText>
                          {fullName.toProperCase()}
                        </S.ExpandedDetailsText>
                        <Badge
                          variant="outline"
                          colorScheme="gray"
                          display="inline-block"
                        >
                          {industryRole}
                        </Badge>
                      </Box>
                    </Flex>
                  </ProfileLink>
                  <S.ExpandedDetailsText grey>
                    {dayjs(timeStamp).fromNow()}
                  </S.ExpandedDetailsText>
                </Flex>
                <SimpleGrid columns={1} spacing={"13px"}>
                  <S.ExpandedDetailsTitle>
                    {subject.toProperCase()}
                  </S.ExpandedDetailsTitle>
                  <Box>
                    <ExpandedCardText small>Type</ExpandedCardText>
                    <ExpandedCardText>
                      {type.toLowerCase() === "for sale"
                        ? "For sale"
                        : "For Lease"}
                    </ExpandedCardText>
                  </Box>
                  <Box>
                    <ExpandedCardText small>Budget</ExpandedCardText>
                    <ExpandedCardText>
                      {minPrice === null ||
                      maxPrice === null ||
                      priceInsensitive
                        ? "Flexible"
                        : `${formatMoney(minPrice)} - ${formatMoney(maxPrice)}`}
                    </ExpandedCardText>
                  </Box>
                  <Box>
                    <ExpandedCardText small>Property types</ExpandedCardText>
                    <ExpandedCardText>
                      {propertyTypes.length > 0
                        ? propertyTypes.join(", ")
                        : "—"}
                    </ExpandedCardText>
                  </Box>
                  <Box>
                    <ExpandedCardText small>Subtypes</ExpandedCardText>
                    <ExpandedCardText>
                      {subTypes.length === 0 && businessTypes.length == 0
                        ? "—"
                        : [...subTypes, ...businessTypes].join(", ")}
                    </ExpandedCardText>
                  </Box>
                  <Box>
                    <ExpandedCardText small>Locations</ExpandedCardText>
                    <ExpandedCardText>
                      {regions === null || regions.length === 0
                        ? "—"
                        : regions.length === 1
                        ? [regions[0].description].join(", ")
                        : regions
                            .map((e) => {
                              return [e.description].join(", ");
                            })
                            .join("; ")}
                    </ExpandedCardText>
                  </Box>
                  <S.ExpandedDetailsText description>
                    {description
                      ? readMore.has(id) && readMore.get(id)
                        ? description.slice(0, 150)
                        : `${description} `
                      : ""}
                    <S.SpanLink
                      onClick={() => {
                        updateReadMore(id, !readMore.get(id));
                      }}
                    >
                      {readMore.has(id) &&
                        (readMore.get(id) ? "...see more" : "see less")}
                    </S.SpanLink>
                  </S.ExpandedDetailsText>
                </SimpleGrid>
              </ExpandedCardDetails>
              {
                !rest['hideMessageButton'] && (
                  <ExpandedCardMessage>
                    <S.ExpandedDetailsText message>
                      Message {firstName.toProperCase()}:
                    </S.ExpandedDetailsText>  
                    <Textarea
                      mt="5px"
                      placeholder={`Send ${firstName.toProperCase()} a message`}
                      name="message"
                      id="message"
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                      focusBorderColor="green.500"
                      w={"100%"}
                      height={"130px"}
                    />
                    {messaged ? (
                      <Flex justifyContent="center">
                        <Tag
                          size="md"
                          colorScheme="green"
                          paddingX="1.1rem"
                          margin="0 auto"
                          mt="8px"
                          paddingY="2px"
                        >
                          <TagLeftIcon as={CheckIcon} size="12px" />
                          <TagLabel>Messaged</TagLabel>
                        </Tag>
                      </Flex>
                    ) : ( 
                      <Button
                        leftIcon={<BsFillChatDotsFill />}
                        colorScheme="green"
                        borderRadius={"0px 0px 22px 22px"}
                        width={"100%"}
                        isLoading={messageStatus == "loading"}
                        loadingText={"Sending message"}
                        isDisabled={uid === userId}
                        onClick={() => {
                          if (profile) {
                            // if (profile?.pro) {
                              createMessageMutation.mutate({
                                uid: userId,
                                message: messageText,
                                wantId: id,
                              });
                            // } else {
                            //   proAlert("Expanded Card View Message Button");
                            // }
                          } else {
                            const authId = id;
                            triggerAuth({
                              hook: "Send message to want owner",
                              authId,
                            });
                            setMessageSendPending(true)
                          }
                        }}
                      >
                        Message {firstName.toProperCase()}
                      </Button>
                    )}
                    <Flex
                      flexDir={"column"}
                      alignItems={"center"}
                      mt={["55px", "55px", "110px", "110px"]}
                    > 
                    {!rest['hideWantAlert'] &&
                        <Button
                          w={["275px", "297px", "297px", "297px"]}
                          fontSize={["14.5px", "16px", "16px", "16px"]}
                          leftIcon={<GoBell />}
                          variant="outline"
                          colorScheme="green"
                          color="green.500"
                          _hover={{ bg: "brand.100" }}
                          borderRadius="22px"
                          mb="10px"
                        >
                          Receive Alerts for Similar Wants
                        </Button>
                      }
                      <Button
                        w="136px"
                        fontSize={["14.5px", "16px", "16px", "16px"]}
                        leftIcon={<BsLink45Deg />}
                        variant="outline"
                        colorScheme="green"
                        color="green.500"
                        _hover={{ bg: "brand.100" }}
                        borderRadius="22px"
                        onClick={() => {
                          copyContent(
                            `https://zonado.com/active-wants/${encodeURIComponent(
                              id
                            )}/${slugify(
                              subject.toProperCase()
                            )}?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing`,
                            id
                          );
                          toast({
                            position: "bottom",
                            title: "Link copied!",
                            status: "success",
                            variant: "subtle",
                            duration: 2000,
                            isClosable: true,
                          });
                        }}
                      >
                        Copy Link
                      </Button>
                    </Flex>
                  </ExpandedCardMessage>
                )
              }
            </ExpandedCard>
          ) : (
            <Box>
              <InnerCardHolder>
                <ContentHolder>
                  <CardTitle>{subject.toProperCase()}</CardTitle>
                  <CardText subGrey>
                    {regions === null || regions.length === 0
                      ? type.toLowerCase() === "for sale"
                        ? "For sale"
                        : "For lease"
                      : regions.length > 2
                      ? // If more than 3 cities, only show first 3
                        `${
                          type.toLowerCase() === "for sale"
                            ? "For sale"
                            : "For lease"
                        } in ` +
                        regions
                          .slice(0, 2)
                          .map((e) => {
                            return [e.description].join(" ");
                          })
                          .join(", ") +
                        ` + ${regions.length - 2} other`
                      : // If only one city, show it with comma separation
                      regions.length === 1
                      ? `${
                          type.toLowerCase() === "for sale"
                            ? "For sale"
                            : "For lease"
                        } in ` + [regions[0].description].join(", ")
                      : `${
                          type.toLowerCase() === "for sale"
                            ? "For sale"
                            : "For lease"
                        } in ` +
                        regions
                          .map((e) => {
                            return [e.description].join(" ");
                          })
                          .join(", ")}
                  </CardText>
                  <CardText>
                    {propertyTypes.length > 0 ? (
                      <>
                        <p>{propertyTypes.join(", ")}</p>
                        <CardText subGrey>
                          {subTypes.length === 0 && businessTypes.length == 0
                            ? "—"
                            : [...subTypes, ...businessTypes].length > 2
                            ? `${[...subTypes, ...businessTypes]
                                .slice(0, 2)
                                .join(", ")} + ${
                                [...subTypes, ...businessTypes].length - 2
                              } other`
                            : [...subTypes, ...businessTypes].join(", ")}
                        </CardText>
                      </>
                    ) : (
                      "—"
                    )}
                  </CardText>
                  <CardText>
                    {minPrice === null || maxPrice === null || priceInsensitive
                      ? "Flexible"
                      : `${formatMoney(minPrice)} - ${formatMoney(maxPrice)}`}
                  </CardText>
                </ContentHolder>
              </InnerCardHolder>
            </Box>
          )}
          {
            !isCollapsed && (
              <Box m="0 auto" pb={"20px"}>
                {expandedRows.includes(id) ? (
                  <IconButton
                    isRound
                    fontSize={"20px"}
                    icon={<FiChevronUp />}
                    aria-label="Expand card"
                    color={"#939393"}
                    bg="transparent"
                    _hover={{
                      bg: "#E2EFE8",
                    }}
                    m="14px 0 -13px 0"
                    onClick={() => {
                      setExpandedRows(expandedRows.filter((e) => e !== id));
                    }}
                  />
                ) : (
                  <IconButton
                    isRound
                    fontSize={"20px"}
                    icon={<FiChevronDown />}
                    aria-label="Expand card"
                    color={"#939393"}
                    bg="transparent"
                    _hover={{
                      bg: "#E2EFE8",
                    }}
                    m="14px 0 -13px 0"
                  />
                )}
              </Box>
            )
          }
        </Flex>
      </OuterCard>

      {(userId == uid && !hideModifyButtons) && (
        <Flex
          justifyContent="flex-end"
          gridColumnGap="1rem"
          w="100%"
          mt="8px"
          mb="16px"
        >
          <Button
            colorScheme="green"
            color="green.500"
            size="sm"
            leftIcon={<EditIcon />}
            borderRadius="20px"
            fontWeight="500"
            paddingX="1.1rem"
            variant="outline"
            onClick={() => {
              setEditId(id);
              setEditMode(true);
              setWantBoxOpen(true);
            }}
          >
            Edit
          </Button>

          <Button
            colorScheme="black"
            size="sm"
            isLoading={submitStatus == "loading"}
            leftIcon={<DeleteIcon />}
            borderRadius="20px"
            fontWeight="500"
            paddingX="1.1rem"
            variant="outline"
            onClick={() => {
              updateMutation.mutate({
                status: "deleted",
              });
            }}
          >
            Delete
          </Button>
        </Flex>
      )}

      <Modal
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        onClose={onModalClose}
        isOpen={isModalOpen}
      >
        <ModalOverlay />
        <ModalContent maxW={"30rem"}>
          {
            modalMode === "sendMessage" ? (
              <>
                <ModalHeader>Send Message</ModalHeader>
                <ModalCloseButton />
                <ModalBody
                  pb={6}
                  maxH="350px"
                  overflowY="auto"
                  whiteSpace="pre-line"
                  border="4px solid $f5f5f5"
                >
                  <Flex mb="16px">
                    {photoURL && <Avatar size="md" src={photoURL} mr="10px" />}

                    <div>
                      <Text>{fullName.toProperCase()}</Text>

                      <Badge
                        variant="outline"
                        colorScheme="gray"
                        display="inline-block"
                      >
                        {industryRole}
                      </Badge>
                    </div>
                  </Flex>

                  <div>
                    <label htmlFor="message">Send Message</label>

                    <Box mb="16px">
                      <Textarea
                        autoFocus
                        focusBorderColor="green.500"
                        value={messageValue}
                        onChange={(e) => {
                          setMessageValue(e.target.value);
                        }}
                        name="message"
                        placeholder="Your message here"
                        // component={Textarea}
                      />
                    </Box>
                  </div>

                  <Button
                    w="100%"
                    mb="20px"
                    colorScheme="green"
                    isLoading={messageStatus == "loading"}
                    loadingText={"Sending message"}
                    // type="submit"
                    onClick={() => {
                      createMessageMutation.mutate({
                        uid: userId,
                        message: messageValue,
                        wantId: id,
                      });
                    }}
                  >
                    Send message
                  </Button>
                </ModalBody>
              </>
            ) :
            modalMode === "upgrade" ? (
              <>
                <ModalHeader>Upgrade to Zonado Pro</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>
                    Upgrade to Zonado Pro to unlock unlimited
                    messages to Want posters.
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button
                    // ref={initialRef}
                    mt="12px"
                    isLoading={submitStatus == "loading"}
                    loadingText="Taking you there..."
                    colorScheme="blue"
                    variant="solid"
                    rightIcon={<ArrowForwardIcon />}
                    onClick={() => {
                      // Only track upgrade attempt if the user is actually signed in
                      if (profile) {
                        createUpgradeMutation.mutate();
                      } else {
                        clientAnalytics.track("Interest in upgrade hook", {
                          reason: "Message want poster",
                          status: "Not logged in",
                          button: "Learn more"
                        });
                        router.push('/pro')
                      }
                    }}
                  >
                    Learn about Zonado Pro
                  </Button>
                </ModalFooter>
              </>
            ) : null
          }
        </ModalContent>
      </Modal>
    </>
  );
};

export default ActiveBuyerCard;
