import styled, { css } from "styled-components";

export const Table = styled.table`
  width: 100%;
  max-width: 1300px;
  border: 1px solid #dee2e6;
  margin: 15px auto 25px;
`;

export const HeaderCell = styled.th`
  text-align: ${(props) => props.align || "left"};
  background: #ecf5ef;
  padding: 10px 0 10px 15px;
  &:nth-child(1) {
    width: 400px;
  }
  &:nth-child(2) {
    width: 150px;
  }
  &:nth-child(3) {
    width: 200px;
  }
  &:nth-child(4),
  &:nth-child(5) {
    width: 20px;
  }
  &:last-child {
    width: 130px;
    padding-right: 10px;
    padding-left: 5px;
    text-align: center;
  }
`;

export const Row = styled.tr`
  border: 1px solid #dee2e6;
  height: 75px;
  & > td {
    padding-left: 15px;

    &:nth-child(3) {
      text-align: right;
    }
    &:nth-child(4) {
      padding-left: 30px;
    }
    &:nth-child(5) {
      padding-left: 5px;
    }
    &:last-child {
      padding-right: 10px;
      padding-left: 5px;
    }
  }
  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
`;

export const Title = styled.p`
  font-size: ${(props) => (props.subtitle ? "12px" : "16px")};
  line-height: ${(props) => (props.subtitle ? "14.52px" : "19.36px")};
  font-weight: ${(props) => (props.subtitle ? "400" : "600")};
  margin-top: ${(props) => props.subtitle && "10px"};
  color: #505050;
`;

export const TableCell = styled.td`
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: ${(props) => (props.type === "buy/rent" ? "14px" : "16px")};
  line-height: ${(props) =>
    props.type === "buy/rent" ? "16.94px" : "19.36px"};
  font-weight: ${(props) => (props.type === "buy/rent" ? "400" : "500")};
  color: ${(props) => (props.type === "buy/rent" ? "#939393" : "#212529")};
`;

export const SubCellText = styled.p`
  margin-top: 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14.52px;
  color: #939393;
`;

export const ExpandedDetailsTitle = styled.p`
  font-size: 20px;
  line-height: 24.2px;
`;

export const ProfileLink = styled.a`
  margin: ${(props) => (props.margin ? `${props.margin}px` : "-10px")};
  padding: 10px;
  transition: 0.3s;
  :hover {
    border-radius: 12px;
    background-color: #f6f6f6;
  }
`;

export const ExpandedDetailsText = styled.p`
  ${(props) => {
    if (props.grey) {
      return css`
        font-size: 14px;
        line-height: 16.94px;
        color: #939393;
      `;
    } else if (props.message) {
      return css`
        font-size: 15px;
        line-height: 18.15px;
        color: #212529;
      `;
    } else if (props.description) {
      return css`
        font-size: 16px;
        line-height: 20.8px;
        max-width: 630px;
        word-wrap: break-word;
        color: #505050;
      `;
    } else {
      return css`
        font-size: 16px;
        line-height: 19.36px;
        color: #212529;
      `;
    }
  }};
  ${(props) =>
    props.locations &&
    css`
      word-wrap: break-word;
      max-width: 448px;
    `}
`;

export const SpanLink = styled.span`
  text-decoration: underline;
  font-weight: 600;
  color: #000;
  cursor: pointer;
`;

export const UpgradeText = styled.p`
  font-family: "Segoe UI", sans-serif;
  font-size: 1rem;
  /* line-height: 1.75; */
`;
