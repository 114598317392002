import styled, { css } from "styled-components";

export const OverallCard = styled.div`
  border: 1px solid #dadce0;
  font-family: "Segoe UI", sans-serif;
  padding: 12px;
  color: #3c4043;
  position: relative;
  border-radius: 8px;
  transition: all 0.3s ease;
`;

export const OuterCard = styled.div`
  font-family: "Inter", sans-serif;
  background: #ffffff;
  box-shadow: ${(props) =>
    props.disable
      ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      : "0px 1px 4px rgba(0, 0, 0, 0.25)"};
  border-radius: 16px;
  position: relative;
  :hover {
    cursor: ${(props) => (props.disable ? "default" : "pointer")};
  }
`;

export const ContentHolder = styled.div`
  font-size: 14px;
  > * {
    margin-bottom: 5px;
  }
`;

export const AreaSpan = styled.div`
  font-size: 14px;
`;

export const PostDetails = styled.div`
  font-size: 14px;
`;

export const PhotoHolder = styled.div`
  width: 150px;
  object-fit: cover;
  height: "auto";
  color: white;
  max-width: 30%;

  @media (max-width: 500px) {
    width: 200px;
    overflow: hidden;
    margin-top: 8px;
  }
  /* min-height: 140px; */
`;
export const SubjectSpan = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

export const PriceDiv = styled.div`
  font-size: 18px;
`;

export const DetailsDiv = styled.div`
  font-weight: 300;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 8px;
`;

export const BoldDetail = styled.span`
  font-weight: 401;
`;

export const InnerCardHolder = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const ExpandedCard = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExpandedCardDetails = styled.div`
  padding: 20px;
  width: 100%;
`;

export const ExpandedCardMessage = styled.div`
  padding: 20px;
  width: 100%;
`;

export const ProfileLink = styled.a`
  :hover {
    text-decoration: underline;
  }
`;

export const CardText = styled.p`
  ${(props) => {
    if (props.grey) {
      return css`
        font-size: 14px;
        line-height: 16.94px;
        color: #939393;
        margin-bottom: 14px;
      `;
    } else if (props.subGrey) {
      return css`
        font-weight: 600;
        font-size: 12px;
        line-height: 14.52px;
        color: #939393;
        margin-bottom: 14px;
      `;
    } else {
      return css`
        font-weight: 500;
        font-size: 15px;
        line-height: 18.15px;
        color: #212529;
      `;
    }
  }}
`;

export const CardTitle = styled.p`
   font-weight: 600;
    font-size: 16px;
    line-height: 19.36px;
    color: #212529;
    margin-bottom: 6px;
`
export const ExpandedCardText = styled.p`
  ${(props) => {
    if (props.small) {
      return css`
        font-size: 14px;
        line-height: 16.94px;
        color: #505050;
        margin-bottom: 4px;
      `;
    } else {
      return css`
        font-size: 16px;
        line-height: 19.36px;
        color: #212529;
        font-weight: 500;
      `;
    }
  }}
`;
